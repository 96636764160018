import React from "react";
import {
  Container,
  Row,
  Col,
  Stack,
  Card,
  Image,
  ListGroup,
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import ImageBar from "../components/ImageBar";
import VideoEmbed from "../components/VideoEmbed";

const TrafficEnf = () => {
  return (
    <>
      <ImageBar image="images/traffic-enf-bar.png" />
      <Container className="text-center my-5">
        <h1 className="poppins-light">Aavadhi in Traffic Enforcement</h1>

        <Stack gap={3}>
          <Container fluid="sm">
            Traffic Enforcement to help make our roads safer. Streamlines
            compliance by integrating seamlessly with upstream and downstream
            systems. Efficiently integrates with upstream and downstream systems
            to enforce ticketing.
          </Container>
          <Container>
            <Row className="justify-content-center mb-4">
              <Col xs={12} sm={6} md={4} className="mb-3">
                <Image
                  src="images/red-light.jpg"
                  thumbnail
                  className="img-fluid custom-image"
                />
              </Col>
              <Col xs={12} sm={6} md={4} className="mb-3">
                <Image
                  src="images/one-way.jpg"
                  thumbnail
                  className="img-fluid custom-image"
                />
              </Col>
              <Col xs={12} sm={6} md={4} className="mb-3">
                <Image
                  src="images/helmetless.jpg"
                  thumbnail
                  className="img-fluid custom-image"
                />
              </Col>
            </Row>

            <Row className="d-flex justify-content-center align-items-start equal-height">
              <Col xs={12} md={6} className="mb-3 d-flex flex-column">
                <Image
                  className="seatbelt flex-grow-1 w-100"
                  src="images/no-seatbelt.jpg"
                  thumbnail
                />
              </Col>

              <Col xs={12} md={6} className="mb-3 d-flex flex-column">
                <Card className="flex-grow-1">
                  <Card.Header className="poppins-light custom-left-align">
                    Traffic Enforcement Use Cases
                  </Card.Header>
                  <Card.Body className="p-0">
                    <ListGroup as="ol" numbered className="w-100 h-100">
                      <ListGroup.Item className="py-3 custom-left-align">
                        Red Light Violation Detection
                      </ListGroup.Item>
                      <ListGroup.Item className="py-3 custom-left-align">
                        One way rule violation
                      </ListGroup.Item>
                      <ListGroup.Item className="py-3 custom-left-align">
                        Number plate detection
                      </ListGroup.Item>
                      <ListGroup.Item className="py-3 custom-left-align">
                        Driving without a seat belt
                      </ListGroup.Item>
                      <ListGroup.Item className="py-3 custom-left-align">
                        Driving and talking on a mobile phone
                      </ListGroup.Item>
                      <ListGroup.Item className="py-3 custom-left-align">
                        Riding without helmet
                      </ListGroup.Item>
                      <ListGroup.Item className="py-3 custom-left-align">
                        Riding on a footpath
                      </ListGroup.Item>
                      <ListGroup.Item className="py-3 custom-left-align">
                        More than 2 riders on a 2-wheeler
                      </ListGroup.Item>
                    </ListGroup>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </Stack>
        <VideoEmbed
          videoId="z-QdK4uwXlA"
          header="Aavadhi Traffic Enforcement Capabilities"
        />
      </Container>
    </>
  );
};

export default TrafficEnf;
